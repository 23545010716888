import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {LoginRequest, LoginResponse} from '../core/interfaces/auth.interface';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private apiUrl = environment.apiUrl;
    private user$ = new BehaviorSubject<any>(null);

    constructor(private http: HttpClient) {
    }

    login(data: LoginRequest): Observable<LoginResponse> {
        return this.http.post<LoginResponse>(`${this.apiUrl}admin/login`, data).pipe(
            tap(res => {
                console.log('res: ', res);
                this.setToken(res.token);
                this.setRole(res.admin.role)
                this.setData(JSON.stringify(res.admin))
            })
        );
    }

    setToken(token: string): void {
        localStorage.setItem('token', token);
    }
    setRole(role:string):void{
        localStorage.setItem('role',role)
    }

    setData(admin:any):void{
        localStorage.setItem("user",admin)
    }

    getToken(): string {
        const token = localStorage.getItem('token');
        return token as string;
    }

    getRole(): string{
        const role= localStorage.getItem('role');
        return role as string
    }

    logout(): void {
        this.user$.next(null);
        localStorage.removeItem('token');
        localStorage.removeItem('role')
        localStorage.removeItem('user');
    }
}
