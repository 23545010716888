import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class LandLoardGuard implements CanActivate {

    constructor(private authService: AuthService,
                private router: Router) {

    }


    canActivate(): boolean {
        const token = this.authService.getToken();
        const role= this.authService.getRole()

        if (token && role==="landlord") {
            return true;
        }
        else{
            this.router.navigate(['/landloard-dashboard'])
            return false
        }

    }

}
