import {Component, Inject, OnInit} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {LoaderService} from './services/loader.service';
import {tap} from 'rxjs/operators';

// @ts-ignore
import packageJson from '../../package.json';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'vonder-event-platform';
    showLoader: boolean = false;
    version = packageJson.version;

    constructor(private loader: LoaderService,
                @Inject(DOCUMENT) private document: Document,) {
    }

    ngOnInit() {
        this.attachVersion();
        this.loader.getLoaderState().asObservable()
            .pipe(
                tap((count) => {
                    if (count > 0) {
                        this.document.body.style.overflow = 'hidden';
                    } else {
                        this.document.body.style.overflow = 'auto';
                    }
                }),
            )
            .subscribe((count) => {
                this.showLoader = count > 0;
            });
    }

    private attachVersion(): void {
        const versionMessage = `App version: ${this.version}`;
        const comment = this.document.createComment(versionMessage);
        this.document.body.appendChild(comment);
        console.log(versionMessage);
    }

}
