<div class="wrapper">
    <form class="login" (ngSubmit)="onSubmit()" [formGroup]="loginForm" >
        <div class="image-holder">
            <img src="assets/images/vonder-logo.svg" alt="">
        </div>
        <mat-form-field appearance="fill">
            <mat-label>Email</mat-label>
            <input formControlName="email" matInput>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Password</mat-label>
            <input formControlName="password" type="password" matInput>
        </mat-form-field>
        <mat-error *ngIf="errorMessage">{{errorMessage}}</mat-error>
        <button mat-flat-button color="primary">login</button>
    </form>
</div>
