import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LoaderService} from '../../services/loader.service';
import {finalize, tap} from 'rxjs/operators';

@Injectable()

export class LoadingInterceptor implements HttpInterceptor {

    constructor(private loader: LoaderService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!request.headers.get('skipLoading')) {
            this.loader.requestStarted();
        }
        return next.handle(request).pipe(
            finalize(() => {
                if (!request.headers.get('skipLoading')) {
                    this.loader.requestFinished()
                }
            })
        );
    }
}
