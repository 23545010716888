import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './core/components/login/login.component';
import {DashboardComponent} from './core/components/dashboard/dashboard.component';
import {AuthGuard} from './core/guards/auth.guard';
import { SuperAdminGuard } from './core/guards/admin.guard';
import { LandLoardGuard } from './core/guards/landloard.guard';
import { AuthService } from './services/auth.service';



const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: '',
        component: DashboardComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'events',
                loadChildren: () => import('./modules/events/events.module').then(m => m.EventsModule),
            },
            {
                path: 'perks',
                loadChildren: () => import('./modules/perks/perks.module').then(m => m.PerksModule)
            },
            {
                path: 'amenities',
                loadChildren: () => import('./modules/amenities/amenities.module').then(m => m.AmenitiesModule)
            },
            {
                path: 'cleaning-rate',
                loadChildren: () => import('./modules/cleaning-rates/cleaning-rates.module').then(m => m.CleaningRatesModule)
            },
            {
                path:'landloard-dashboard',
                loadChildren:()=> import('./modules/lanloard-dashboard/lanloard-dashboard.module').then(m=>m.LanloardDashboardModule),
                canActivate:[LandLoardGuard]
            },

            // {
            //     path: 'blogs',
            //     loadChildren: () => import('./modules/blogs/blogs.module').then(m => m.BlogsModule)
            // },
            // {
            //     path:'category',
            //     loadChildren:()=> import('./modules/category/category.module').then(m=>m.CategoryModule)
            // },
            // {
            //     path:'location',
            //     loadChildren:()=> import('./modules/location/location.module').then(m=>m.LocationModule)
            // },
        // {
        //     path:'applicant',
        //     loadChildren:()=> import('./modules/applicant/applicant.module').then(m=>m.ApplicantModule)
        // },
        {
            path:'user',
            loadChildren:()=> import('./modules/user/user.module').then(m=>m.UserModule),
            canActivate:[SuperAdminGuard]
        },
        // {
        //     path:'changepassword',
        //     loadChildren:()=> import('./modules/changepassword/changepassword.module').then(m=>m.ChangepasswordModule),
        // },
            {
                path: '**',
                redirectTo: '/events'
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
