import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class SuperAdminGuard implements CanActivate {

    constructor(private authService: AuthService,
                private router: Router) {

    }


    canActivate(): boolean {
        const token = this.authService.getToken();
        const role= this.authService.getRole()
        if (token && role==="super-admin") {
            return true;
        } else {
            this.router.navigate(['/events'])
            return false;
        }

    }

}
