<mat-toolbar color="primary">
    <button mat-icon-button (click)="drawer.toggle()" *ngIf="userrole!=='landlord'">
        <mat-icon>menu</mat-icon>
    </button>
    <div class="logo">
        <img src="assets/images/vonder-white.svg" alt="">
    </div>
    <span>Admin panel</span>
    <span class="spacer"></span>
    <!-- <a *ngIf="userrole !=='admin'" routerLink="/changepassword" class="changePassword">Change Password</a> -->
    <button mat-icon-button (click)="onLogout()">
        <mat-icon>logout</mat-icon>
    </button>
</mat-toolbar>
<mat-drawer-container class="example-container" autosize>
    <mat-drawer #drawer class="example-sidenav" mode="side">
        <mat-nav-list>
            <a mat-list-item routerLink="/events">
                <mat-icon matListIcon>date_range</mat-icon>
                Events
            </a>
            <a mat-list-item routerLink="/perks">
                <mat-icon matListIcon>local_activity</mat-icon>
                Perks
            </a>
            <a mat-list-item routerLink="/amenities">
                <mat-icon matListIcon>fitness_center</mat-icon>
                Amenities
            </a>
            <a mat-list-item routerLink="/cleaning-rate">
                <mat-icon matListIcon>cleaning_services</mat-icon>
                Cleaning rates
            </a>
            <!-- <a mat-list-item routerLink="/blogs">
                <mat-icon matListIcon>assignment</mat-icon>
                Blogs
            </a> -->
            <!-- <a mat-list-item routerLink="/category">
                <mat-icon matListIcon>list_alt</mat-icon>
                Category
            </a> -->
            <!-- <a mat-list-item routerLink="/location">
                <mat-icon matListIcon> add_location</mat-icon>
                Location
            </a> -->
            <!-- <a mat-list-item routerLink="/applicant">
                <mat-icon matListIcon>home</mat-icon>
                Applicants
            </a> -->
            <a mat-list-item routerLink="/user" *ngIf="userrole==='super-admin'">
                <mat-icon matListIcon>person</mat-icon>
                User
            </a>
        </mat-nav-list>

    </mat-drawer>

    <mat-drawer-content>
        <div class="main-content">
            <router-outlet></router-outlet>
        </div>

    </mat-drawer-content>

</mat-drawer-container>
