import {ApplicationRef, ChangeDetectorRef, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ChangeDetection} from '@angular/cli/lib/config/schema';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    private loaderState$ = new BehaviorSubject<number>(0);

    constructor(private ref: ApplicationRef) {
    }

    requestStarted(): void {
        let count = this.loaderState$.getValue();
        count += 1;
        this.loaderState$.next(count);
    }

    requestFinished(): void {
        let count = this.loaderState$.getValue();
        count -= 1;
        this.loaderState$.next(count);
        this.ref.tick()
    }

    getLoaderState(): BehaviorSubject<number> {
        return this.loaderState$;
    }

}
